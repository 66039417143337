  #create-body {
    margin: auto;
    width: 60%;
    border-radius: 5px;
    padding: 30px;
    text-align: left;
  }

  .rounded-card>div:nth-child(2) {
    border-radius: 25px;
  }

  .italicize {
    font-style: italic;
  }

  .button-container {
    display: flex;
    margin: auto;
    text-transform: none;
    gap: 40px;
  }

  .button-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  #create-op-button,
  #cancel-op-button {
    text-transform: none;
    margin: auto;
    font-size: 1.1rem;
    font-weight: 500;
  }

  #create-op-button {
    width: 100%;
    background-color: black;
    color: white;
    border-radius: 5px;
    font-weight: bolder;
  }

  #cancel-op-button {
    background-color: white;
    color: black;
  }

  input {
    width: 80px;
  }

  /* .box-text-space {
    margin: 0 0;
  } */

  .MuiInputBase-formControl {
    margin: 20px 0;
    text-align: left;
    padding-left: 10px;
  }

  .MuiTextField-root {
    margin: 5px;
  }

  .MuiInputLabel-outlined {
    margin: 6px 2px;
    font-weight: bolder;
    font-size: 1.44rem;
    color: black;
  }

  .hidden {
    display: none;
  }

  .create-form-gap {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .create-form-gap h3 {
    margin: 0;
    font-size: 14px;
    margin: 0 6px;
    padding-bottom: 5px;
  }