.selectTab{
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .functionButton{
    transition-duration: 0.3s;
    height: 30px;
    background-color: transparent;
    border-width: 0px;
    color: grey;
    font-weight: 700;
    font-size: 24px;
    font-style: italic;
  }
  
  .functionButton:hover{
    color: #303030;
  }


  .functionButton.enableFunButton{
    color: black;
    text-decoration: underline;
  }