.common-button {
  position: relative;
  min-width: 160px;
  height: 40px;
  font-size: var(--p-size);
  font-weight: 700;
  line-height: var(--p-line-weight);
  padding: 10px 16px;
  border-radius: 100px;
  transition-duration: 0.3s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
}
.storePosition{
  align-self: flex-end;
  top: 10px;
}

.blackButton{
  background-color: var(--black);
  color: var(--white);
  border: none;
}

.whiteButton{
  background-color: var(--white);
  color: gray;
  border: 1px solid gray
}

.walletPosition{
  margin-right: 30px;
  margin-top: 15px;
}

.common-button:hover {
  cursor: pointer;
  background-color: gainsboro;
  color: black;
}

.common-button:active {
  background-color: grey;
}

.center-button{
  align-self: center;
}

.home-page-button{
  margin-top: 80px;
}