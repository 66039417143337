.leftTab {
    padding-top: 60px;
    position: relative;
    min-width: 240px;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-right: 1px solid;
    border-color: gainsboro;
  }

  .unselectedButton{
    transition-duration: 0.3s;
    border-radius: 10px;
    min-width: 160px;
    height: 30px;
    background-color: transparent;
    border-width: 0px;
    text-align: left;
    color: grey;
    font-weight: bold;
    padding: 0 18px;
    margin: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 3px;
  }
  
  .unselectedButton:hover{
    background-color: grey;
    color: black;
  }

  .unselectedButton:active {
    background-color: gainsboro;
    color: black;
  }

  .selectedButton{
    background-color: gainsboro;
    color: black;
  }