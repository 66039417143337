.landingWrapper {
  height: 100%;
  text-align: left;
}

.landingWrapper .landingContent {
  height: 100%;
  background-image: url("./images/home-bg.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

.landingContent{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 60px;
  gap: 10px;
}

.header{
  display: flex;
  width: 100%;
  height: 0;
  position: relative;
  flex-direction: row;
  justify-content: flex-end;
}