.login-box {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.login-button {
    color: #333;
    background-color: white;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.chain-button {
    color: #333;
    background-color: white;
    border: none;
    font-size: 14px;
    font-weight: 400;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    height: auto;
}