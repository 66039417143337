.common-button {
    position: relative;
    height: 40px;
    font-size: 18px;
    font-weight: 700;
    padding: 10px 16px;
    border-radius: 10px;
    transition-duration: 0.3s;
    background-color: #333;
    color: white;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: auto;
}

.common-button:hover {
    cursor: pointer;
    background-color: gainsboro;
    color: #333;
}

.common-button:disabled,
.common-button[disabled] {
    cursor: none;
    background-color: gainsboro;
    color: grey;
}

.common-button:active {
    background-color: grey;
}