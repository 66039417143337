.storeWrapper {
  height: 100%;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.rightTab {
  width: 100%;
  height: 100% - 200px;
  display: flex;
  flex-direction: column;
}

.upBox {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
}

.mainBox {
  width: 100%;
}

.homeWrapper .homeContent {
  padding: 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-image: url("./images/home-bg.png");
  background-repeat: no-repeat;
}

.header {
  display: flex;
  width: 100%;
  height: 0;
  position: relative;
  flex-direction: row;
  justify-content: flex-end;
}

.right-top-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
  height: 100%;
}