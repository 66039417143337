:root {
  --p1-size: 120px;
  --p1-weight: 700;
  --p1-line-height: 180px;

  --p2-size: 70px;
  --p2-weight: 200;

  --p-size: 14px;
  --p-bold-weight: 600px;
  --p-line-weight: 20px;

  --black: black;
  --white: white;
}

.p1 {
  font-size: var(--p1-size);
  font-weight: var(--p1-weight);
  line-height: var(--p1-line-height);
  margin: 0;
}

.p2 {
  font-size: var(--p2-size);
  font-weight: var(--p2-weight);
  margin: 0;
}
